import { IMenuItem } from '@/types';

export const formatServingSize = (item: IMenuItem): string => {
  const { recipeMainServingSize, recipeServingSizeG } = item.attributes;

  if (!recipeMainServingSize) {
    return '';
  }

  return (
    recipeMainServingSize +
    (recipeServingSizeG ? ` (${recipeServingSizeG}g)` : '')
  );
};
