import { useNavigation } from '@react-navigation/native';
import { observer } from 'mobx-react';
import { Center, IconButton, Pressable } from 'native-base';
import React from 'react';
import { Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Colors from '@/constants/Colors';
import { useRootStore } from '@/data';
import { logEvent } from '@/utils';

import { CalendarIcon } from './icons';

export const CalendarNavButton = observer(() => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const { uiStore } = useRootStore();

  const handlePress = () => {
    //@ts-ignore
    navigation.navigate('DayMenuStack', { screen: 'DayMenu' });
    uiStore.toggleDatePicker();
    logEvent('calendarNavPressed');
  };

  const action = (
    <Center
      alignSelf="center"
      p="1.5"
      position="absolute"
      rounded="full"
      bottom={`${insets.bottom}px`}
      backgroundColor="white"
      mb="1.5"
    >
      <IconButton
        bg="mg.green.300"
        colorScheme="dark"
        icon={
          <CalendarIcon
            size={32}
            color={uiStore.isDatePickerOpen ? Colors.primaryDark : 'white'}
          />
        }
        onPress={handlePress}
        p="2.5"
        rounded="full"
        shadow="4"
      />
    </Center>
  );

  if (Platform.OS === 'android') {
    return action;
  }

  return (
    <Pressable onPress={handlePress} zIndex="100">
      {action}
    </Pressable>
  );
});
