import { Box, IBoxProps } from 'native-base';
import React, { PropsWithChildren } from 'react';

export const Card = ({ children, ...rest }: PropsWithChildren<IBoxProps>) => {
  return (
    <Box width="full" rounded="lg" p="4" bg="white" shadow="2" {...rest}>
      {children}
    </Box>
  );
};
