import { observer } from 'mobx-react';
import { Avatar, Heading, HStack, ScrollView, Text, VStack } from 'native-base';
import React from 'react';
import { StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import {
  DietaryFlagSelection,
  LegalDisclaimerFooter,
  LocationChanger,
  LogoutButton,
} from '@/components';
import { useRootStore } from '@/data';

const ProfileScreen = observer(() => {
  const {
    userStore: { currentUser: user },
  } = useRootStore();

  if (!user) {
    return null;
  }

  return (
    <>
      <SafeAreaView style={styles.safeArea} edges={['top']} />
      <ScrollView bg="white" contentContainerStyle={styles.scrollView}>
        <VStack>
          <HStack space={[2, 4]} alignItems="center" bg="white" p="4">
            <Avatar
              source={{ uri: user.picture }}
              size={{ base: 'md', lg: 'lg' }}
            />
            <VStack flex="1">
              <Heading size={{ base: 'md', lg: 'lg' }}>{user.name}</Heading>
              <Text fontSize={{ base: 'sm', lg: 'md' }}>{user.email}</Text>
            </VStack>
            <LogoutButton />
          </HStack>
          <LocationChanger />
        </VStack>
        <DietaryFlagSelection />
        <LegalDisclaimerFooter />
      </ScrollView>
    </>
  );
});

const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  safeArea: { flex: 0, backgroundColor: 'white' },
  separator: {
    marginVertical: 12,
    height: 1,
    width: '80%',
  },
});

export default ProfileScreen;
