import { Image, Text } from 'native-base';
import React from 'react';

import { Container, ContainerProps } from './Container';
import { EmphaticText } from './EmphaticText';
import { TextProps } from './TextProps';

export const Calendar = (props: ContainerProps) => {
  return (
    <Container title="Choose a Day and Meal" {...props}>
      <Text {...TextProps}>
        <EmphaticText>Select a day</EmphaticText> and{' '}
        <EmphaticText>a meal</EmphaticText> from the top of the screen.
      </Text>
      <Text px="5" textAlign="center">
        <EmphaticText>Click the date or the calendar icon</EmphaticText> to plan
        your menus in advance
      </Text>

      <Image
        mt="6"
        source={require('@/assets/images/choose-day.png')}
        resizeMode="stretch"
        height="20"
        alt="header"
      />
    </Container>
  );
};
