import 'react-calendar/dist/Calendar.css';
import './DatePicker.css';

import { useNavigation } from '@react-navigation/native';
import { observer } from 'mobx-react';
import { ChevronLeftIcon, ChevronRightIcon } from 'native-base';
import React from 'react';
import Calendar from 'react-calendar';

import { Config } from '@/constants';
import { useRootStore } from '@/data';

const DatePicker = observer(() => {
  const { menuStore, uiStore } = useRootStore();
  const navigation = useNavigation();

  const maxDate = new Date();
  const minDate = new Date();
  maxDate.setDate(maxDate.getDate() + Config.maxDayRange / 2);
  minDate.setDate(minDate.getDate() - Config.maxDayRange / 2);

  const handleChange = (date: Date) => {
    menuStore.setCurrentDate(date);
    uiStore.closeDatePicker();
    // @ts-ignore
    navigation.navigate('DayMenu');
  };

  return (
    <Calendar
      calendarType="US"
      value={menuStore.currentDate}
      onChange={handleChange}
      nextLabel={<ChevronRightIcon color="mg.dark.200" />}
      next2Label={null}
      prevLabel={<ChevronLeftIcon color="mg.dark.200" />}
      prev2Label={null}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
});

export default DatePicker;
