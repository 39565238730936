import { useNavigation } from '@react-navigation/native';
import { observer } from 'mobx-react';
import { ChevronLeftIcon, ChevronRightIcon, HStack, Text } from 'native-base';
import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { Config } from '@/constants';
import { useRootStore } from '@/data';
import { logEvent } from '@/utils';

export const CurrentDate = observer(
  ({ disabled, dateOnly }: { disabled: boolean; dateOnly?: boolean }) => {
    const store = useRootStore();
    const navigation = useNavigation();

    const maxDate = new Date();
    const minDate = new Date();
    maxDate.setDate(maxDate.getDate() + Config.maxDayRange / 2);
    minDate.setDate(minDate.getDate() - Config.maxDayRange / 2);

    const createArrowHandler = (fn: Function) => () => {
      fn.apply(store.menuStore);
      // @ts-ignore
      navigation.navigate('DayMenu');
    };

    const handleDatePress = () => {
      store.uiStore.toggleDatePicker();
      logEvent('headerDatePressed');
    };

    const leftDisabled = disabled || store.menuStore.currentDate <= minDate;
    const rightDisabled = disabled || store.menuStore.currentDate >= maxDate;

    return (
      <HStack alignItems="center">
        {!dateOnly && (
          <TouchableOpacity
            disabled={leftDisabled}
            delayPressIn={0}
            style={styles.leftArrow}
            onPressIn={createArrowHandler(store.menuStore.goOneDayBefore)}
          >
            <ChevronLeftIcon
              color={leftDisabled ? 'mg.gray.200' : 'mg.dark.200'}
            />
          </TouchableOpacity>
        )}
        <TouchableOpacity
          disabled={disabled}
          delayPressIn={0}
          onPressIn={handleDatePress}
        >
          <Text fontSize={{ base: 'md', lg: 'xl' }}>
            {store.menuStore.currentDateForUI}
          </Text>
        </TouchableOpacity>
        {!dateOnly && (
          <TouchableOpacity
            disabled={rightDisabled}
            delayPressIn={0}
            style={styles.rightArrow}
            onPressIn={createArrowHandler(store.menuStore.goOneDayAhead)}
          >
            <ChevronRightIcon
              color={rightDisabled ? 'mg.gray.200' : 'mg.dark.200'}
            />
          </TouchableOpacity>
        )}
      </HStack>
    );
  },
);

const styles = StyleSheet.create({
  leftArrow: {
    marginRight: 2,
    paddingHorizontal: 2,
  },
  rightArrow: {
    marginLeft: 2,
    paddingHorizontal: 2,
  },
});
