import { RouteProp } from '@react-navigation/native';
import { observer } from 'mobx-react-lite';
import { Box } from 'native-base';
import React, { useEffect } from 'react';

import {
  DayMenuDetails,
  DayMenuHeader,
  DayMenuProvider,
  MenuLoading,
  NoMenuAvailable,
} from '@/components';
import { useRootStore } from '@/data';

const INDEX_OF_DAYPART = {
  breakfast: 0,
  lunch: 1,
  dinner: 2,
};

const EmbedScreen = observer(
  (props: {
    route: RouteProp<{
      params: {
        dayPart: 'breakfast' | 'lunch' | 'dinner';
        slug: string;
        date: string;
      };
    }>;
  }) => {
    const { menuStore } = useRootStore();
    const { params } = props.route;

    useEffect(() => {
      if (!params.date || !params.dayPart || !params.slug) {
        return;
      }
      menuStore.fetchMenu({
        slug: params.slug,
        date: params.date.replaceAll('-', '/'),
      });
      menuStore.setCurrentDate(new Date(params.date));
    }, [params, menuStore]);

    if (menuStore.isLoading) {
      return <MenuLoading />;
    }

    const dayPart = menuStore.dayParts?.[INDEX_OF_DAYPART[params.dayPart]];

    return (
      <DayMenuProvider dayPart={props.route.params.dayPart}>
        <Box borderBottomColor="mg.gray.200" borderBottomWidth="2">
          <DayMenuHeader dateOnly />
        </Box>
        {menuStore.menuItems?.length ? (
          <DayMenuDetails dayPart={dayPart} />
        ) : (
          <NoMenuAvailable />
        )}
      </DayMenuProvider>
    );
  },
);

export default EmbedScreen;
