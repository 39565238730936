import { Image, Text } from 'native-base';
import React from 'react';

import { Container, ContainerProps } from './Container';
import { EmphaticText } from './EmphaticText';
import { TextProps } from './TextProps';

export const MenuItems = (props: ContainerProps) => {
  return (
    <Container title="Menu Items" {...props}>
      <Text {...TextProps}>
        <Text>Menu items display </Text>
        <EmphaticText>at-a-glance nutritional information</EmphaticText>
        <Text> and </Text>
        <EmphaticText>known allergens</EmphaticText>
        {'. '}
        <Text>
          Click an item to see detailed nutritional facts and ingredient list.
        </Text>
      </Text>

      <Image
        mt="6"
        source={require('@/assets/images/food-card.png')}
        resizeMode="stretch"
        height="24"
        alt="food-card example"
      />
    </Container>
  );
};
