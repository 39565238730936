import * as AuthSession from 'expo-auth-session';
import * as Random from 'expo-random';
import { useCallback, useEffect, useState } from 'react';
import { Platform } from 'react-native';

import { Config } from '@/constants';
import { retrieveFromSecureStore, storeFragmented } from '@/secure-store';
import Sentry from '@/sentry';

const authorizationEndpoint = `https://${Config.auth0.domain}/authorize`;
const useProxy = Platform.select({
  web: false,
  default: Config.isDevelopment,
});
const redirectUri = AuthSession.makeRedirectUri({
  useProxy,
  path: '/menu/index',
});
const NONCE = String(Random.getRandomBytes(32));

export const useAuth0IdToken = () => {
  const [idToken, setIdToken] = useState<string>();

  const [, authResponse, promptAsync] = AuthSession.useAuthRequest(
    {
      redirectUri,
      clientId: Config.auth0.clientId!,
      responseType: 'token id_token',
      scopes: ['openid', 'profile', 'email'],
      extraParams: {
        nonce: NONCE,
        grantType: 'authorization_code',
      },
    },
    { authorizationEndpoint },
  );

  const fetchIdToken = useCallback(async () => {
    if (Platform.OS === 'ios') {
      AuthSession.dismiss();
    }
    const storedIdToken = await retrieveFromSecureStore('mg.idToken');
    if (storedIdToken) {
      setIdToken(storedIdToken);
    } else {
      await promptAsync({ useProxy })
        .then(response => {
          if (response.type === 'success') {
            const token = response.authentication?.idToken;
            if (token) {
              setIdToken(token);
              return storeFragmented('mg.idToken', token);
            }
          }
        })
        .catch(Sentry.captureException);
    }
  }, [promptAsync]);

  useEffect(() => {
    if (authResponse && authResponse.type !== 'success') {
      Sentry.addBreadcrumb({
        category: 'auth',
        message: JSON.stringify(authResponse),
      });
      Sentry.captureMessage('Auth error in useAuth0IdToken');
    } else {
      Sentry.addBreadcrumb({
        message: `isDevelopment=${Config.isDevelopment}`,
      });
      Sentry.addBreadcrumb({
        message: `domain=${Config.auth0.domain}`,
      });
      Sentry.addBreadcrumb({
        message: `process.env.IS_DEVELOPMENT=${process.env.IS_DEVELOPMENT}`,
      });
      Sentry.captureEvent({
        message: 'Config state',
      });
    }
  }, [authResponse]);

  return {
    request: fetchIdToken,
    idToken,
    cancelled: ['cancel', 'dismiss'].includes(authResponse?.type || ''),
  };
};
