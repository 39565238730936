import { Button, Center, Heading, VStack } from 'native-base';
import React, { PropsWithChildren } from 'react';

export type ContainerProps = {
  goNext?: () => void;
};

export const Container = ({
  children,
  title,
  goNext,
}: PropsWithChildren<
  {
    title: string;
  } & ContainerProps
>) => {
  return (
    <VStack px="6" pt="8" w="full" alignItems="center" rounded="lg" bg="white">
      <Heading fontWeight="600" mb="3" fontSize="lg" borderTopRadius="md">
        {title}
      </Heading>
      {children}
      {goNext && (
        <Center mt="auto" p="8">
          <Button variant="primary" px="8" onPress={() => goNext()}>
            Next
          </Button>
        </Center>
      )}
    </VStack>
  );
};
