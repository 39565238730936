import { observer } from 'mobx-react';
import { Button, Text, VStack } from 'native-base';
import React from 'react';

import { useRootStore } from '@/data';

import { Container, ContainerProps } from './Container';
import { TextProps } from './TextProps';

export const GetStarted = observer((props: ContainerProps) => {
  const { userStore, uiStore } = useRootStore();

  const completeAndNavigate = (
    destination: 'DayMenuStack' | 'ProfileStack',
  ) => {
    userStore.completeOnboarding();
    uiStore.setFirstScreen(destination);
  };

  return (
    <Container title="Complete!" {...props}>
      <Text {...TextProps}>
        {userStore.currentLocation
          ? 'Where you would like to begin.'
          : 'Start by adding a location and personalizing your allergens'}
      </Text>
      <VStack space="3" mt="8">
        {userStore.currentLocation && (
          <Button
            variant="primary"
            onPress={() => completeAndNavigate('DayMenuStack')}
          >
            Today's Menu
          </Button>
        )}
        <Button
          variant="primary"
          onPress={() => completeAndNavigate('ProfileStack')}
        >
          Personalize My Allergens
        </Button>
      </VStack>
    </Container>
  );
});
