import { Box, Divider, Heading, HStack, Text, VStack } from 'native-base';
import React from 'react';

import { IMenuItem } from '@/types';
import { formatMenuItem, formatServingSize } from '@/utils';

import { Card } from './Cards';
import { Ingredients } from './Ingredients';

const NutritionalRow = ({
  label,
  quantity,
  percentage,
  secondary,
}: {
  label: string;
  quantity: string | null;
  percentage?: number | null;
  secondary?: boolean;
}) => {
  return (
    <>
      <Divider backgroundColor="black" />
      <HStack>
        <Text
          fontWeight={secondary ? '400' : '500'}
          marginRight="1"
          marginLeft={secondary ? '3' : 0}
        >
          {label}
        </Text>
        <Text>{quantity}</Text>
        {percentage ? (
          <Text marginLeft="auto" fontWeight="600">
            {percentage}%
          </Text>
        ) : null}
      </HStack>
    </>
  );
};
NutritionalRow.defaultProps = {
  percentage: null,
  secondary: false,
};

export const NutritionalLabel = ({ item }: { item: IMenuItem }) => {
  const servingSize = formatServingSize(item);
  const nutritionalInfo = item.attributes.recipeNutrition;

  if (!nutritionalInfo) {
    return null;
  }

  const formatted = formatMenuItem(item);

  const sectionProps = {
    p: '1',
    borderWidth: '1',
    borderColor: 'black',
    w: '100%',
  };

  return (
    <Card>
      <VStack>
        <VStack {...sectionProps}>
          <Heading fontWeight="600">Nutrition Facts</Heading>
          {!!servingSize && (
            <Text marginBottom="1">Serving Size: {servingSize}</Text>
          )}
        </VStack>
        <Box height="4" w="100%" bg="black" />
        <HStack {...sectionProps} alignItems="center">
          <VStack flex="1">
            <Heading fontSize="sm" fontWeight="500" borderBottomColor="black">
              Amount Per Serving
            </Heading>
            <Text fontSize="md" fontWeight="600">
              Calories
            </Text>
          </VStack>
          <Text fontSize="4xl" fontWeight="600">
            {Math.floor(nutritionalInfo.calories)}
          </Text>
        </HStack>
        <Box height="2" w="100%" bg="black" />
        <VStack {...sectionProps}>
          <Text w="100%" textAlign="right">
            % Daily Value¹
          </Text>
          <NutritionalRow
            label="Total Fat"
            quantity={formatted.fats}
            percentage={nutritionalInfo.fatPercentage}
          />
          <NutritionalRow
            label="Saturated Fat"
            quantity={formatted.saturatedFats}
            percentage={nutritionalInfo.fatSaturatedPercentage}
            secondary
          />
          <NutritionalRow
            label="Trans Fat"
            quantity={formatted.transFats}
            percentage={nutritionalInfo.fatTransPercentage}
            secondary
          />
          <NutritionalRow
            label="Cholesterol"
            quantity={formatted.cholesterol}
            percentage={nutritionalInfo.cholesterolPercentage}
          />
          <NutritionalRow
            label="Sodium"
            quantity={formatted.sodium}
            percentage={nutritionalInfo.sodiumPercentage}
          />
          <NutritionalRow
            label="Total Carbohydrate"
            quantity={formatted.carbs}
            percentage={nutritionalInfo.cargsPercentage}
          />
          <NutritionalRow
            label="Dietary Fiber"
            quantity={formatted.fibers}
            percentage={nutritionalInfo.fiberPercentage}
          />
          <NutritionalRow label="Sugars" quantity={formatted.sugar} />
          <NutritionalRow label="Protein" quantity={formatted.protein} />
        </VStack>

        <Text p="1" lineHeight="sm" textAlign="right">
          ¹ Percent Daily Values are based on a 2,000 calorie diet. Your daily
          values may be higher or lower depending on your calorie needs.
        </Text>
        <Ingredients item={item} marginTop="1" />
      </VStack>
    </Card>
  );
};
