import axios, { CancelToken } from 'axios';

import { Config } from '@/constants';
import Sentry from '@/sentry';

interface DietaryFlag {
  id: string;
  name: string;
}

interface APIDietaryFlag {
  attributes: {
    galley_id: string;
    name: string;
  };
}

const formatDietaryFlags = (dietaryFlags: APIDietaryFlag[]) => {
  return dietaryFlags
    .map(({ attributes }) => ({
      id: attributes.galley_id,
      name: attributes.name,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
};

export class MenuHubService {
  static async fetchLocation(token: string) {
    const url = `${Config.apiUrl}/locations/${token.toLowerCase()}`;
    return axios.get(url);
  }

  static async fetchMenu(
    {
      locationSlug,
      startDate,
    }: {
      locationSlug: string;
      startDate: string;
    },
    cancelToken?: CancelToken,
  ) {
    const url = `${Config.apiUrl}/menus/${locationSlug}?filter[start_date]=${startDate}&filter[interval_name]=Day`;
    return axios.get(url, { cancelToken });
  }

  static async fetchDietaryFlags(): Promise<{
    canonical: DietaryFlag[];
    nonCanonical: DietaryFlag[];
  }> {
    const url = (canonical: boolean) =>
      `${Config.apiUrl}/dietary_flags?canonical=${canonical}`;
    const [canonical, nonCanonical] = await Promise.all([
      axios
        .get(url(true))
        .then(res => res.data.data)
        .catch(error => {
          Sentry.captureException(error);
          return [];
        }),
      axios
        .get(url(false))
        .then(res => res.data.data)
        .catch(error => {
          Sentry.captureException(error);
          return [];
        }),
    ]);

    return {
      canonical: formatDietaryFlags(canonical),
      nonCanonical: formatDietaryFlags(nonCanonical),
    };
  }
}
