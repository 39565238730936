import { useEffect, useState } from 'react';
import { AppState, AppStateStatus } from 'react-native';

import { useRootStore } from '@/data';

export const AppStateListener = () => {
  const { menuStore } = useRootStore();
  const [lastUpdated, setLastUpdated] = useState(Date.now());
  const [appState, setAppState] = useState<AppStateStatus>(
    AppState.currentState,
  );

  useEffect(() => {
    const handleAppStateChange = (nextAppState: AppStateStatus) => {
      setAppState(nextAppState);
    };
    AppState.addEventListener('change', handleAppStateChange);

    return () => {
      AppState.removeEventListener('change', handleAppStateChange);
    };
  }, []);

  useEffect(() => {
    if (appState === 'active') {
      if (Date.now() - lastUpdated > 1000 * 60 * 60 * 24) {
        menuStore.fetchDietaryFlags();
        setLastUpdated(Date.now());
      }
    }
  }, [appState, menuStore, lastUpdated]);

  return null;
};
