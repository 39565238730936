import { Center, Heading, Image, Text, VStack } from 'native-base';
import React from 'react';

import { Config } from '@/constants';

import { PrivacyPolicyLink } from './PrivacyPolicyLink';

export const LegalDisclaimerFooter = () => {
  return (
    <VStack space="3" bg="mg.bg" paddingBottom="2">
      <Image
        source={require('@/assets/images/eggplant.png')}
        resizeMode="stretch"
        position="absolute"
        height="3/4"
        width={[110, 180]}
        marginLeft="-8"
        bottom="-12"
        alt="decorative background image"
      />
      <Center paddingX="12" paddingY="6">
        <Heading
          size={{ base: 'sm', lg: 'lg' }}
          fontWeight="500"
          marginBottom={[0.5, 4]}
        >
          Legal Disclaimers
        </Heading>
        <Text
          fontWeight="300"
          textAlign="center"
          fontSize={{ base: 'md', lg: 'xl' }}
        >
          By using our product you have agreed to our Privacy Policy and Terms
          of Service.
        </Text>
        <Text fontWeight="300" fontSize={{ base: 'md', lg: 'xl' }}>
          You can <PrivacyPolicyLink label="review them here" />
        </Text>
      </Center>
      <Center w="full" mb="2">
        <Text color="mg.dark.200" fontSize={{ base: 'md', lg: 'xl' }}>
          Version {Config.appVersion}{' '}
          {Config.appSha ? `(${Config.appSha.slice(0, 6)})` : ''}
        </Text>
      </Center>
    </VStack>
  );
};
