import { MaterialIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { observer } from 'mobx-react';
import {
  Button,
  Center,
  FormControl,
  Heading,
  Icon,
  Input,
  KeyboardAvoidingView,
  VStack,
  WarningOutlineIcon,
} from 'native-base';
import React from 'react';
import { Platform, StyleSheet } from 'react-native';

import { MenuHubService } from '@/api';
import { useRootStore } from '@/data';
import Sentry from '@/sentry';
import { logEvent } from '@/utils';

export const AddLocation = observer(() => {
  const { userStore } = useRootStore();
  const navigation = useNavigation();

  const [token, setToken] = React.useState<string>('');
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');

  const attemptAdd = () => {
    if (userStore.locations?.find(x => x.token === token.toLowerCase())) {
      setError('Already registered');
      return;
    }

    setSubmitting(true);
    MenuHubService.fetchLocation(token)
      .then(({ data }) => {
        if (data.error) {
          setError(data.error);
          logEvent('locationAddFailed');
        } else {
          userStore.addLocation(data);
          navigation.goBack();
          logEvent('locationAddSuccess');
        }
      })
      .catch(Sentry.captureException)
      .finally(() => setSubmitting(false));
  };

  const handleCancel = () => {
    navigation.goBack();
    logEvent('locationAddCancel');
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={styles.container}
    >
      <Center px="6" w="full" h="full" maxW="2xl" mx="auto">
        <Icon as={<MaterialIcons name="location-pin" />} size="48" />
        <Heading size="lg" mb="8" fontWeight="600" textAlign="center">
          Add Location
        </Heading>
        <VStack px="12" space="4" w="full">
          <FormControl isRequired isInvalid={!!error}>
            <Input
              placeholder="Dining Location Code"
              size="2xl"
              textTransform="uppercase"
              _focus={{
                borderColor: 'mg.green.300',
              }}
              autoCapitalize="characters"
              autoCorrect={false}
              onChangeText={setToken}
              value={token}
              isDisabled={submitting}
              isInvalid={!!error}
            />

            <FormControl.ErrorMessage
              leftIcon={<WarningOutlineIcon size="xs" />}
            >
              {error}
            </FormControl.ErrorMessage>
            <FormControl.HelperText>
              Don't know it? Ask a staff member.
            </FormControl.HelperText>
          </FormControl>

          <VStack>
            <Button
              variant="primary"
              onPress={attemptAdd}
              isLoading={submitting}
              disabled={submitting || !token}
            >
              Add
            </Button>
            <Button
              variant="link"
              onPress={handleCancel}
              _text={{ fontWeight: '400' }}
            >
              Cancel
            </Button>
          </VStack>
        </VStack>
      </Center>
    </KeyboardAvoidingView>
  );
});

const styles = StyleSheet.create({
  container: { flex: 1 },
});
