import { useNavigation } from '@react-navigation/native';
import { Text } from 'native-base';
import React from 'react';
import { Platform } from 'react-native';

export const PrivacyPolicyLink = ({ label }: { label: string }) => {
  const navigation = useNavigation();

  const handlePress = () => {
    if (Platform.OS === 'web') {
      window.open('https://merig.com/menu-app-privacy-policy', '_blank');
    } else {
      navigation.navigate('Privacy');
    }
  };

  return (
    <Text underline fontWeight="300" color="mg.green.300" onPress={handlePress}>
      {label}
    </Text>
  );
};
