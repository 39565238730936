import { observer } from 'mobx-react';
import { ScrollView, Stack } from 'native-base';
import React, { useRef } from 'react';

import { useRootStore } from '@/data';
import { categorizeMenu, filterByDayPart } from '@/utils';

import { FoodCategory } from './FoodCategory';
import { MenuLoading } from './MenuLoading';

// odd indexes for the left, even for the right
const ALL_IMAGES = [
  require('@/assets/images/seasonal/spring_28.png'),
  require('@/assets/images/seasonal/spring_73.png'),
  require('@/assets/images/seasonal/fall_40.png'),
  require('@/assets/images/seasonal/fall_3.png'),
  require('@/assets/images/seasonal/fall_53.png'),
  require('@/assets/images/seasonal/fall_41.png'),
  require('@/assets/images/seasonal/fall_68.png'),
  require('@/assets/images/seasonal/winter_10.png'),
  require('@/assets/images/seasonal/winter_44.png'),
  require('@/assets/images/seasonal/winter_47.png'),
];

const getRandomInt = (max: number) => Math.floor(Math.random() * (max + 1));

export const DayMenuDetails = observer(({ dayPart }: { dayPart?: string }) => {
  const { menuStore } = useRootStore();
  const { menuItems: menu, isLoading: fetching } = menuStore;
  // multiply by 2 to ensure odd indexes are used for left and even for right.
  const firstImageIndex = useRef(getRandomInt(ALL_IMAGES.length) * 2);

  if (fetching) {
    return <MenuLoading />;
  }

  const daypartMenu = filterByDayPart(menu!, dayPart);
  const categorizedMenu = categorizeMenu(daypartMenu)!;

  return (
    <ScrollView width="100%" bg="white">
      <Stack space="4" marginY="6" maxW="2xl" mx="auto">
        {categorizedMenu.map((category, index) => {
          const leftIndex =
            (index + firstImageIndex.current) % ALL_IMAGES.length;
          const rightIndex =
            (index + firstImageIndex.current + 1) % ALL_IMAGES.length;
          return (
            <FoodCategory
              key={category.id}
              category={category}
              leftImageSource={
                index % 4 === 0 ? ALL_IMAGES[leftIndex] : undefined
              }
              rightImageSource={
                index % 4 === 2 ? ALL_IMAGES[rightIndex] : undefined
              }
            />
          );
        })}
      </Stack>
    </ScrollView>
  );
});
