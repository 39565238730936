import { Heading, Text, VStack } from 'native-base';
import React from 'react';

import { IMenuItem } from '@/types';

const formatIngredient = (ingredientRaw: string) => {
  const ingredient = ingredientRaw.replace('\n', '');
  const start = ingredient.match(/\[/);
  const end = ingredient.match(/\]/);
  const ingredientMakeUp =
    start && end
      ? ingredient.slice(start.index! - 1, end.index! + 1)
      : undefined;
  const mainIngredient = ingredientMakeUp
    ? ingredient.replace(ingredientMakeUp, '')
    : ingredient;

  return (
    mainIngredient.trim().split(', ').reverse().join(' ') +
    (ingredientMakeUp || '')
  );
};

export const Ingredients = ({
  item,
  marginTop,
}: {
  item: IMenuItem;
  marginTop: number | string | undefined;
}) => {
  if (!item.attributes.recipeIngredients) {
    return null;
  }

  return (
    <VStack marginTop={marginTop}>
      <Heading textTransform="uppercase" fontSize="sm" fontWeight="600">
        Ingredients
      </Heading>
      <Text textTransform="uppercase">
        {item.attributes.recipeIngredients.map(formatIngredient).join(', ')}
      </Text>
    </VStack>
  );
};
