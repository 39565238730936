import * as Analytics from 'expo-firebase-analytics';
import { Platform } from 'react-native';

export const logEvent = (
  eventName: string,
  properties: Record<string, any> = {},
) => {
  if (Platform.OS === 'web') {
    // @ts-ignore
    window.gtag('event', eventName, properties);
  } else {
    Analytics.logEvent(eventName, properties);
  }
};
