export const getClosestDayPartIndex = (dayParts: string[]) => {
  if (dayParts.length <= 1) {
    return 0;
  }

  const today = new Date();
  const currentHours = today.getHours();

  if (dayParts.length === 2) {
    if (currentHours < 12) {
      return 0;
    }
    return 1;
  }

  if (currentHours > 5 && currentHours < 10) {
    return 0;
  }
  if (currentHours >= 10 && currentHours < 16) {
    return 1;
  }

  return 2;
};
