import { Entypo } from '@expo/vector-icons';
import { HStack, IconButton, Text } from 'native-base';
import { IViewProps } from 'native-base/lib/typescript/components/basic/View/types';
import React from 'react';

export const DismissableMessage = ({
  message,
  variant,
  onClose,
  ...rest
}: IViewProps & {
  message: string;
  variant: 'success' | string;
  onClose: () => void;
}) => {
  const bg = variant === 'success' ? 'mg.green.100' : 'light';
  return (
    <HStack
      {...rest}
      position="absolute"
      top="0"
      maxW="2xl"
      p="2"
      mx="2"
      w="full"
      bgColor={bg}
      roundedBottom="lg"
    >
      <Text textAlign="center" flex="1">
        {message}
      </Text>
      <IconButton
        colorScheme="dark"
        icon={<Entypo name="cross" />}
        ml="auto"
        onPress={onClose}
      />
    </HStack>
  );
};
