import { Box, Image, Text } from 'native-base';
import React from 'react';

import { Container, ContainerProps } from './Container';
import { EmphaticText } from './EmphaticText';
import { TextProps } from './TextProps';

export const Allergens = (props: ContainerProps) => {
  return (
    <Container title="Allergens" {...props}>
      <Text {...TextProps}>
        You can <EmphaticText>personalize known allergens,</EmphaticText>{' '}
        warnings and dietary flags
        <EmphaticText> in your profile</EmphaticText>.
      </Text>

      <Box shadow="3" mt="6">
        <Image
          source={require('@/assets/images/dietary-flags.png')}
          resizeMode="stretch"
          height="32"
          alt="dietary flags example"
        />
      </Box>
    </Container>
  );
};
