import { IImageProps, Image } from 'native-base';
import React from 'react';

export const MerigLogo = (props: IImageProps) => {
  return (
    <Image
      mt="6"
      source={require('@/assets/images/merig.png')}
      resizeMode="contain"
      height="32"
      w="full"
      alt="merig logo"
      {...props}
    />
  );
};
