import axios from 'axios';
import camelize from 'camelize-ts';

import { IUser } from '@/auth0/types';
import { Config } from '@/constants';
import {
  deleteStoredValue,
  retrieveFromSecureStore,
  storeFragmented,
} from '@/secure-store';
import Sentry from '@/sentry';
import { ILocation } from '@/types';

export class ProfileService {
  user: IUser | undefined;

  constructor(user?: IUser) {
    this.user = user;
  }

  setUser(user: IUser) {
    this.user = user;
  }

  static async isExistingTokenStillValid() {
    const expirationEpoch = await retrieveFromSecureStore(
      'mg.accessTokenExpiracy',
    );
    if (expirationEpoch) {
      if (new Date().getTime() < parseInt(expirationEpoch, 10) * 1000) {
        return true;
      }
    }
    return false;
  }

  static async fetchAccessToken() {
    if (await this.isExistingTokenStillValid()) {
      return await retrieveFromSecureStore('mg.accessToken');
    }

    const url = `https://${Config.auth0.domain}/oauth/token`;
    const audience = `https://${Config.auth0.domain}/api/v2/`;

    const body = {
      expires_in: 86400,
      grant_type: 'client_credentials',
      client_id: Config.auth0.apiId,
      client_secret: Config.auth0.apiSecret,
      audience: audience,
    };

    const response = await axios.post(url, body, {
      headers: { 'content-type': 'application/json' },
    });

    if (response?.data) {
      const { access_token, expires_in } = response.data;
      await storeFragmented(
        'mg.accessTokenExpiracy',
        String(new Date().getTime() / 1000 + expires_in),
      );
      await storeFragmented('mg.accessToken', access_token);
    }

    return response?.data?.access_token;
  }

  static async fetchUser({
    userId,
    accessToken,
  }: {
    userId: string;
    accessToken: string;
  }) {
    const userResponse = await fetch(
      `https://${Config.auth0.domain}/api/v2/users/${userId}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    const apiUser = await userResponse.json();
    apiUser.metadata = camelize(apiUser.user_metadata);

    return apiUser;
  }

  async respondToTerms(accepted: boolean) {
    const body = {
      user_metadata: {
        acceptedTermsV1: accepted,
      },
    };
    return this.updateUser(body);
  }

  async completeOnboarding() {
    const body = {
      user_metadata: {
        mobileOnboardingComplete: true,
      },
    };
    return this.updateUser(body);
  }

  async updateDietaryFlags(dietaryFlagIds: string[]) {
    const body = {
      user_metadata: {
        dietaryFlagIds,
      },
    };
    return this.updateUser(body);
  }

  async updateLocation(currentLocation: ILocation, locations: ILocation[]) {
    const body = {
      user_metadata: {
        current_location: {
          ...currentLocation,
          calorie_display: currentLocation.calorieDisplay,
        },
        locations,
      },
    };
    return this.updateUser(body);
  }

  async updateUser(body: any) {
    const userId = this.user?.user_id;
    if (!userId) {
      return;
    }
    const accessToken = await ProfileService.fetchAccessToken();
    const url = `https://${Config.auth0.domain}/api/v2/users/${userId}`;
    const success = await axios
      .patch(url, body, {
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
      .then(() => true)
      .catch(error => {
        Sentry.captureException(error);
        return false;
      });
    return success;
  }

  async logout() {
    await Promise.all([
      deleteStoredValue('mg.idToken'),
      deleteStoredValue('mg.accessTokenExpiracy'),
      deleteStoredValue('mg.accessToken'),
      deleteStoredValue('mg.accessTokenExpiracy'),
    ]);
  }
}
