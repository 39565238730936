import camelize from 'camelize-ts';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

import { MenuHubService, ProfileService } from '@/api';
import { IUser } from '@/auth0/types';
import Sentry from '@/sentry';
import { CalorieDisplayType, ILocation, IMenuItem } from '@/types';
import { formatDateForApi, formatDateForUI } from '@/utils';

// see https://dev.to/ivandotv/mobx-root-store-pattern-with-react-hooks-318d
// for reference on the pattern used
export class RootStore {
  userStore: UserStore;
  menuStore: MenuHubStore;
  uiStore: UIStore;

  constructor() {
    this.userStore = new UserStore(this);
    this.menuStore = new MenuHubStore(this);
    this.uiStore = new UIStore(this);
  }
}

class UIStore {
  rootStore: RootStore;
  isDatePickerOpen: boolean = false;
  firstScreen: 'DayMenuStack' | 'ProfileStack' | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      isDatePickerOpen: observable,
      firstScreen: observable,

      closeDatePicker: action,
      openDatePicker: action,
      setFirstScreen: action,
      toggleDatePicker: action,
    });
  }

  openDatePicker() {
    this.isDatePickerOpen = true;
  }
  closeDatePicker() {
    this.isDatePickerOpen = false;
  }
  toggleDatePicker() {
    this.isDatePickerOpen = !this.isDatePickerOpen;
  }

  setFirstScreen(screen: 'DayMenuStack' | 'ProfileStack') {
    this.firstScreen = screen;
  }
}

class UserStore {
  acceptedTerms: boolean = false;
  currentLocation: ILocation | null = null;
  currentUser: IUser | null = null;
  rootStore: RootStore;
  dietaryFlagIds: string[] = [];
  locations: ILocation[] | null = null;
  onboardingComplete: boolean = false;
  transportLayer: ProfileService;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      acceptedTerms: observable,
      currentLocation: observable,
      currentUser: observable,
      dietaryFlagIds: observable,
      locations: observable,
      onboardingComplete: observable,

      acceptTerms: action,
      addLocation: action,
      authenticated: action,
      changeCurrentLocation: action,
      completeOnboarding: action,
      logout: action,
      rejectTerms: action,
      setCurrentLocation: action,
      toggleDietaryFlagTracking: action,
      updateDietaryFlags: action,
    });

    this.transportLayer = new ProfileService();
  }

  addLocation(location: ILocation) {
    if (this.locations?.find(x => x.token === location.token)) {
      return;
    }

    this.locations = [...(this.locations || []), location];
    this.changeCurrentLocation(location.slug);
  }

  authenticated(user: IUser) {
    this.currentUser = user;
    this.transportLayer?.setUser(user);
    this.acceptedTerms = this.currentUser.metadata.acceptedTermsV1;
    this.currentLocation = this.currentUser.metadata.currentLocation;
    this.dietaryFlagIds = this.currentUser.metadata.dietaryFlagIds || [];
    this.locations = this.currentUser.metadata.locations;
    this.onboardingComplete =
      this.currentUser.metadata.mobileOnboardingComplete;
  }

  setCurrentLocation(location: ILocation) {
    this.currentLocation = location;
  }

  acceptTerms() {
    this.acceptedTerms = true;
    this.transportLayer?.respondToTerms(true);
  }
  rejectTerms() {
    this.acceptedTerms = false;
    this.transportLayer?.respondToTerms(false);
    // TODO: figure out what to do here... should we close the app? do nothing?
  }
  completeOnboarding() {
    this.onboardingComplete = true;
    this.transportLayer?.completeOnboarding();
  }

  async toggleDietaryFlagTracking(dietaryFlagId: string) {
    if (!this.dietaryFlagIds) {
      return;
    }

    if (this.dietaryFlagIds.includes(dietaryFlagId)) {
      this.updateDietaryFlags(
        this.dietaryFlagIds.filter(x => x !== dietaryFlagId),
      );
    } else {
      this.updateDietaryFlags([...this.dietaryFlagIds, dietaryFlagId]);
    }
  }

  async updateDietaryFlags(dietaryFlagIds: string[]) {
    this.dietaryFlagIds = dietaryFlagIds;
    this.transportLayer?.updateDietaryFlags(dietaryFlagIds);
  }

  changeCurrentLocation(slug: string) {
    const newLocation = this.locations?.find(x => x.slug === slug);
    if (newLocation) {
      this.currentLocation = newLocation;
      this.rootStore.menuStore.fetchMenu();
      this.transportLayer?.updateLocation(
        this.currentLocation,
        this.locations!,
      );
    }
  }

  syncCurrentLocation() {
    const token = this.currentLocation?.token;
    if (!token) {
      return;
    }
    MenuHubService.fetchLocation(token)
      .then(
        action('fetchSuccess', ({ data: response }) => {
          const updatedLocation = camelize(response) as ILocation;
          this.locations = [
            ...(this.locations || []).filter(x => x.token !== token),
            updatedLocation,
          ];
          this.changeCurrentLocation(updatedLocation.slug);
        }),
      )
      .catch(Sentry.captureException);
  }

  async logout() {
    await this.transportLayer.logout();
    runInAction(() => {
      this.currentUser = null;
    });
  }
}

class MenuHubStore {
  menuItems: IMenuItem[] | null = null;
  currentDate: Date = new Date();
  locationName: string | null = null;
  dietaryFlags: {
    canonical: { id: string; name: string }[];
    nonCanonical: { id: string; name: string }[];
  } | null = null;
  rootStore: RootStore;
  isLoading: boolean = false;
  calorieDisplay: CalorieDisplayType | null = null;
  dayClosedMessage: string = '';

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      calorieDisplay: observable,
      currentDate: observable,
      dietaryFlags: observable,
      isLoading: observable,
      locationName: observable,
      menuItems: observable,
      dayClosedMessage: observable,

      fetchDietaryFlags: action,
      fetchMenu: action,
      goOneDayAhead: action,
      goOneDayBefore: action,
      setCurrentDate: action,
      setIsLoading: action,
      _setCalorieDisplay: action,

      currentDateForAPI: computed,
      currentDateForUI: computed,
      dayParts: computed,
      dietaryFlagIds: computed,
      isDayClosed: computed,
    });
    this.rootStore = rootStore;
    this.fetchDietaryFlags();
  }
  setCurrentDate(date: Date) {
    this.currentDate = date;
    this.fetchMenu();
  }
  goOneDayBefore() {
    this.currentDate = new Date(
      this.currentDate.setDate(this.currentDate.getDate() - 1),
    );
    this.fetchMenu();
  }
  goOneDayAhead() {
    this.currentDate = new Date(
      this.currentDate.setDate(this.currentDate.getDate() + 1),
    );
    this.fetchMenu();
  }
  async fetchDietaryFlags() {
    await MenuHubService.fetchDietaryFlags().then(dietaryFlags => {
      runInAction(() => {
        this.dietaryFlags = dietaryFlags;
      });
    });
  }
  fetchMenu(overrides?: { slug: string; date: string }) {
    const locationSlug =
      overrides?.slug || this.rootStore.userStore.currentLocation?.slug;
    if (!locationSlug || this.isLoading) {
      return;
    }

    this.setIsLoading(true);

    return MenuHubService.fetchMenu({
      locationSlug,
      startDate: overrides?.date || this.currentDateForAPI,
    })
      .then(
        action('fetchSuccess', ({ data: response }) => {
          this.menuItems = camelize(response.data) as IMenuItem[];
          this.calorieDisplay = response.meta.location.calorie_display;
          this.dayClosedMessage = response.meta.closeReason;
          this.locationName = response.meta.location.name;
        }),
      )
      .catch(e => {
        runInAction(() => {
          this.menuItems = [];
        });
        Sentry.addBreadcrumb({
          category: 'menuHub',
          message: `Attempted to fetch menu for ${locationSlug} but failed`,
        });
        Sentry.captureException(e);
        // apparently there are cases where the hub returns a 404 even though the
        // location exists and params are valid.
        // e.g. https://hub-staging.merig.com/api/menus/test-the-steward-school?filter[start_date]=12/12/2021&filter[interval_name]=Day
      })
      .finally(
        action('fetchFinished', () => {
          this.setIsLoading(false);
        }),
      );
  }
  setIsLoading(loading: boolean) {
    this.isLoading = loading;
  }
  // only here for testing purposes, please don't use in the app
  _setCalorieDisplay(calorieDisplay: CalorieDisplayType) {
    this.calorieDisplay = calorieDisplay;
  }

  get currentDateForAPI() {
    return formatDateForApi(this.currentDate);
  }

  get currentDateForUI() {
    return formatDateForUI(this.currentDate);
  }

  get dietaryFlagIds() {
    return [
      ...new Set([...(this.dietaryFlags?.canonical || []).map(x => x.id)]),
      ...(this.dietaryFlags?.nonCanonical || []).map(x => x.id),
    ];
  }

  get isDayClosed() {
    return !!this.dayClosedMessage;
  }

  get dayParts() {
    if (!this.menuItems) {
      return null;
    }

    const newDayParts: string[] | undefined = [...this.menuItems]
      .sort((a, b) => a.attributes.daypartOrder - b.attributes.daypartOrder)
      .map(x => x.attributes.daypartName);
    return [...new Set(newDayParts)];
  }
}
