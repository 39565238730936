import { Text } from 'native-base';
import React from 'react';

import { Container, ContainerProps } from './Container';
import { TextProps } from './TextProps';

export const Intro = (props: ContainerProps) => {
  return (
    <Container title="Welcome" {...props}>
      <Text {...TextProps}>
        This quick guide will show you the primary features of the app and help
        you customize the dining app to serve you better.
      </Text>
    </Container>
  );
};
