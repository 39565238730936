import { StatusBar } from 'expo-status-bar';
import { NativeBaseProvider } from 'native-base';
import React from 'react';
import { Platform } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import * as Sentry from 'sentry-expo';

import { AuthProvider } from '@/auth0';
import { AppStateListener } from '@/components';
import { Config } from '@/constants';
import { RootStoreProvider } from '@/data';
import { customTheme, CustomThemeType } from '@/theme';

import useCachedResources from './app/hooks/useCachedResources';
import Navigation from './app/navigation';

Sentry.init({
  dsn: Config.sentryDSN,
  enableInExpoDevelopment: Config.sentryDebugOn,
  debug: Config.sentryDebugOn,
});

declare module 'native-base' {
  interface ICustomTheme extends CustomThemeType {}
}

function App() {
  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <NativeBaseProvider theme={customTheme}>
        <AuthProvider>
          <SafeAreaProvider>
            <RootStoreProvider>
              <AppStateListener />
              <Navigation />
            </RootStoreProvider>
            <StatusBar style="dark" />
          </SafeAreaProvider>
        </AuthProvider>
      </NativeBaseProvider>
    );
  }
}

export default Platform.OS === 'web' ? App : Sentry.Native.wrap(App);
