import React, { PropsWithChildren } from 'react';

export const DayMenuContext = React.createContext({});
DayMenuContext.displayName = 'DayMenuContext';

export const DayMenuProvider = ({
  dayPart,
  children,
}: PropsWithChildren<{ dayPart: string }>) => {
  return (
    <DayMenuContext.Provider value={dayPart}>
      {children}
    </DayMenuContext.Provider>
  );
};
