import { Box, Center, Image, Text, ZStack } from 'native-base';
import React from 'react';

import { useIsTablet } from '@/hooks/useIsTablet';

import { MenuLoading } from './MenuLoading';

export const Announcement = ({
  text,
  isLoading,
}: {
  text: string;
  isLoading: boolean;
}) => {
  const isTablet = useIsTablet();

  if (isLoading) {
    return <MenuLoading />;
  }

  return (
    <ZStack flex="1" bg="white">
      <Center
        w="full"
        h="full"
        flex="1"
        borderTopWidth="2"
        borderColor="mg.gray.200"
        zIndex="1"
      />
      <Image
        source={require('@/assets/images/announcement/21.png')}
        resizeMode="contain"
        alt="first decorative image"
        w={[200, 400]}
        h={[200, 400]}
        left={[-32, -64]}
        top={[-32, -64]}
      />

      <Center w="full" h="full" flex="1" zIndex="1" px="8">
        <Box
          borderColor="mg.green.300"
          borderWidth="2"
          px="4"
          py="6"
          rounded="xl"
          bg="white"
          alignSelf="center"
          w="full"
        >
          <Text textAlign="center" fontSize={{ base: 'lg', lg: '2xl' }}>
            {text}
          </Text>
        </Box>
      </Center>
      <Image
        source={require('@/assets/images/announcement/38.png')}
        resizeMode="contain"
        alt="second decorative image"
        w={[200, 400]}
        h={[200, 400]}
        right={[-24, -48]}
        bottom="50%"
        style={{
          transform: [
            { translateY: isTablet ? 320 : 180 },
            { rotate: '-20deg' },
          ],
        }}
        zIndex="2"
      />
      <Image
        source={require('@/assets/images/announcement/pepper.png')}
        resizeMode="contain"
        alt="third decorative image"
        w={[200, 400]}
        h={[200, 400]}
        left={[-64, -128]}
        bottom={[-96, -192]}
        style={{ transform: [{ rotate: '10deg' }] }}
      />
    </ZStack>
  );
};
