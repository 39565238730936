import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';

import { observer } from 'mobx-react';
import {
  Center,
  Heading,
  Image,
  ScrollView,
  VStack,
  ZStack,
} from 'native-base';
import React from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import { MerigLogo } from '@/components';
import { Card } from '@/components/Cards';
import {
  Allergens,
  Calendar,
  GetStarted,
  Intro,
  MenuItems,
  TermsAcceptance,
} from '@/components/Onboarding';
import { useRootStore } from '@/data';

SwiperCore.use([Pagination]);

const SCREENS = [
  {
    key: 'intro',
    screen: Intro,
  },
  {
    key: 'day',
    screen: Calendar,
  },
  {
    key: 'recipe',
    screen: MenuItems,
  },
  {
    key: 'allergens',
    screen: Allergens,
  },
  {
    key: 'getStarted',
    screen: GetStarted,
  },
];

const SWIPER_STYLE = { maxWidth: '100%' };

const OnboardingScreen = observer(() => {
  const { userStore } = useRootStore();

  return (
    <ZStack h="full" bg="white" w="full">
      <Image
        mt="6"
        source={require('@/assets/images/nux-bg-complete.png')}
        resizeMode="center"
        alt="new user experience background"
        w="full"
        h="full"
        position="absolute"
        bottom={0}
      />
      <ScrollView w="full" h="full">
        <VStack
          w="full"
          h="full"
          maxW="md"
          minH="full"
          mx="auto"
          p="8"
          alignContent="center"
          justifyContent="center"
          alignSelf="center"
        >
          <Center mb="6" alignContent="flex-start">
            <MerigLogo />
          </Center>
          <Card alignItems="center" p="0" mb="8" maxH="xl">
            {userStore.acceptedTerms ? (
              <>
                <Center
                  bg="mg.green.200"
                  px="4"
                  py="6"
                  w="full"
                  roundedTop="lg"
                >
                  <Heading color="white" fontWeight="600" textAlign="center">
                    {userStore.currentLocation?.name}
                  </Heading>
                </Center>
                <Swiper
                  slidesPerView={1}
                  centeredSlides={true}
                  pagination={{
                    clickable: true,
                  }}
                  style={SWIPER_STYLE}
                >
                  {SCREENS.map(meta => (
                    <SwiperSlide key={meta.key}>
                      <meta.screen />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </>
            ) : (
              <TermsAcceptance />
            )}
          </Card>
        </VStack>
      </ScrollView>
    </ZStack>
  );
});

export default OnboardingScreen;
