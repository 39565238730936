import { Heading, IImageProps, Image, Stack, View } from 'native-base';
import React from 'react';
import { ImageSourcePropType, StyleSheet } from 'react-native';

import { ICategory } from '@/types';

import { PressableFoodCard } from './Cards';

const imageProps: IImageProps = {
  alt: 'category decorative bg image',
  resizeMode: 'contain',
  position: 'absolute',
  height: [160, 240],
  width: [160, 240],
  marginTop: -2,
  zIndex: -1,
};

export const FoodCategory = ({
  category,
  rightImageSource,
  leftImageSource,
}: {
  category: ICategory;
  rightImageSource?: ImageSourcePropType;
  leftImageSource?: ImageSourcePropType;
}) => {
  const { name, items } = category;

  return (
    <View position="relative">
      {leftImageSource && (
        <Image
          source={leftImageSource}
          {...imageProps}
          marginLeft={[-8, -16]}
          style={{ transform: [{ rotate: '30deg' }] }}
        />
      )}
      {rightImageSource && (
        <Image
          source={rightImageSource}
          {...imageProps}
          marginRight={[-8, -16]}
          right={0}
        />
      )}

      <Heading
        fontSize={{ base: '2xl', lg: '4xl' }}
        marginBottom="4"
        textAlign="center"
        fontWeight="600"
        style={styles.heading}
      >
        {name}
      </Heading>
      <Stack space="5" marginX="6">
        {items.map(item => (
          <PressableFoodCard item={item} key={item.id} />
        ))}
      </Stack>
    </View>
  );
};

const styles = StyleSheet.create({
  heading: {
    textShadowColor: 'white',
    textShadowRadius: 16,
    textShadowOffset: { width: 0, height: 0 },
  },
});

FoodCategory.defaultProps = {
  leftImageSource: undefined,
  rightImageSource: undefined,
};
