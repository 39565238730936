import { observer } from 'mobx-react';
import { Center, Heading, Image, VStack, ZStack } from 'native-base';
import React from 'react';
import { ImageSourcePropType, Platform } from 'react-native';
import Collapsible from 'react-native-collapsible';

import { useRootStore } from '@/data';
import { useIsTablet } from '@/hooks/useIsTablet';

import { CurrentDate } from './CurrentDate';
import { DatePicker } from './DatePicker';

const FALL_IMAGES = [
  require('@/assets/images/header/left/fall.png'),
  require('@/assets/images/header/right/fall.png'),
];
const WINTER_IMAGES = [
  require('@/assets/images/header/left/winter.png'),
  require('@/assets/images/header/right/winter.png'),
];
const SUMMER_IMAGES = [
  require('@/assets/images/header/left/summer.png'),
  require('@/assets/images/header/right/summer.png'),
];
const SPRING_IMAGES = [
  require('@/assets/images/header/left/spring.png'),
  require('@/assets/images/header/right/spring.png'),
];

const SEASON_MAP: Record<number, ImageSourcePropType[]> = {
  0: FALL_IMAGES, // Jan
  1: WINTER_IMAGES, // Feb
  2: SPRING_IMAGES, // Mar
  3: SPRING_IMAGES, // Apr
  4: SPRING_IMAGES, // May
  5: SUMMER_IMAGES, // June
  6: SUMMER_IMAGES, // July
  7: SUMMER_IMAGES, // Aug
  8: FALL_IMAGES, // Sep
  9: FALL_IMAGES, // Oct
  10: FALL_IMAGES, // Nov
  11: WINTER_IMAGES, // Dec
};

const SeasonalBackgroundImage = () => {
  const images = SEASON_MAP[new Date().getMonth()];
  return (
    <>
      <Image
        width="24"
        height="24"
        top="0"
        left="0"
        resizeMode="cover"
        alt="logo"
        source={images[0]}
        position="absolute"
      />
      <Image
        width="24"
        height="24"
        bottom="0"
        right="0"
        resizeMode="cover"
        alt="logo"
        source={images[1]}
        position="absolute"
      />
    </>
  );
};

export const DayMenuHeader = observer(
  ({ dateOnly }: { dateOnly?: boolean }) => {
    const {
      menuStore,
      uiStore: { isDatePickerOpen },
      userStore,
    } = useRootStore();
    const disabled = !userStore.currentLocation;
    const isTablet = useIsTablet();

    return (
      <VStack width="100%" bg="white" zIndex="2">
        <Center>
          <SeasonalBackgroundImage />
          <ZStack
            minHeight={
              isDatePickerOpen ? (isTablet ? 'lg' : 96) : isTablet ? 32 : 24
            }
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
          >
            <Center minHeight="32" w="full" justifyContent="flex-start">
              <Center w="full" alignSelf="flex-start">
                <Heading
                  fontSize={{ base: 'md', lg: '2xl' }}
                  fontWeight="600"
                  marginTop="4"
                  color="mg.dark.300"
                >
                  {menuStore.locationName}
                </Heading>
                <CurrentDate disabled={disabled} dateOnly={dateOnly} />
              </Center>
              {Platform.OS === 'web' ? (
                isDatePickerOpen ? (
                  <DatePicker />
                ) : null
              ) : (
                <Collapsible
                  easing="linear"
                  duration={80}
                  collapsed={!isDatePickerOpen}
                >
                  <DatePicker />
                </Collapsible>
              )}
            </Center>
          </ZStack>
        </Center>
      </VStack>
    );
  },
);
