export default {
  background: '#F7F7F7',
  borderColor: '#CDCDCD',
  darkGray: 'rgba(60, 60, 60, 0.3)',
  darkGreen: '#7FC04A',
  shadowColor: '#171717',
  primaryDark: '#3C3C3C',
  secondaryDark: '#666666',
  disabledDark: '#BBBBBB',
  primaryGreen: '#7FC04A',
  secondaryGreen: '#BFDFA4',
  gray: 'rgba(60, 60, 60, 0.5)',
  red: '#BB112A',
};
