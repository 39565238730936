import { useEffect, useState } from 'react';

import { ProfileService } from '@/api';
import Sentry from '@/sentry';

export const useAuth0AccessToken = () => {
  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    let cancelled = false;

    ProfileService.fetchAccessToken()
      .then(result => {
        if (!cancelled) {
          setAccessToken(result);
        }
      })
      .catch(Sentry.captureException);
    return () => {
      cancelled = true;
    };
  }, []);

  return accessToken;
};
