import { observer } from 'mobx-react';
import React from 'react';

import { useRootStore } from '@/data';

import { Announcement } from './Announcement';

export const NoMenuAvailable = observer(() => {
  const { menuStore } = useRootStore();

  return (
    <Announcement
      text="We're sorry. There's no menu for this meal."
      isLoading={menuStore.isLoading}
    />
  );
});
