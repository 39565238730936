import { IMenuItem } from '@/types';
import { ICategory } from '@/types/ICategory';

export const categorizeMenu = (menu?: IMenuItem[]): ICategory[] => {
  if (!menu) {
    return [];
  }

  const categoriesSummary = menu.reduce<
    Record<number, { name: string; order: number }>
  >((result, item) => {
    if (result[item.attributes.categoryId]) {
      return result;
    }
    return {
      ...result,
      [item.attributes.categoryId]: {
        name: item.attributes.categoryName,
        order: item.attributes.categoryDayDaypartOrder,
      },
    };
  }, {});

  return Object.keys(categoriesSummary)
    .reduce<ICategory[]>((result, categoryIdStr) => {
      const categoryId = parseInt(categoryIdStr, 10);
      return [
        ...result,
        {
          id: categoryId,
          name: categoriesSummary[categoryId].name,
          order: categoriesSummary[categoryId].order,
          items: menu.filter(item => item.attributes.categoryId === categoryId),
        },
      ];
    }, [])
    .sort((a, b) => a.order - b.order);
};
