import { DeviceType, getDeviceTypeAsync } from 'expo-device';
import { useEffect, useState } from 'react';

export const useIsTablet = () => {
  const [isTablet, setIsTablet] = useState<boolean>(false);
  useEffect(() => {
    getDeviceTypeAsync().then(deviceType => {
      setIsTablet(deviceType === DeviceType.TABLET);
    });
  }, []);

  return isTablet;
};
