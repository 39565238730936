import { useAuth0 } from '@auth0/auth0-react';
import { observer } from 'mobx-react';
import { Button } from 'native-base';
import React from 'react';

import { useRootStore } from '@/data';

export const LogoutButton = observer(() => {
  const { userStore } = useRootStore();
  const { logout } = useAuth0();

  if (!userStore.currentUser) {
    return null;
  }

  const handleLogout = () => {
    logout({
      returnTo: `${window.location.origin}/?logout=true`,
    });
  };

  return (
    <Button
      borderRadius="full"
      colorScheme="red"
      _pressed={{
        bg: 'red.600:alpha.60',
      }}
      onPress={handleLogout}
    >
      Logout
    </Button>
  );
});
