import jwtDecode, { JwtPayload } from 'jwt-decode';
import { useEffect, useState } from 'react';

import { ProfileService } from '@/api';
import Sentry from '@/sentry';

import { useAuth0AccessToken } from './useAuth0AccessToken';
import { useAuth0IdToken } from './useAuth0IdToken';

export const useAuth0User = () => {
  const [user, setUser] = useState();
  const { idToken, cancelled, request } = useAuth0IdToken();
  const accessToken = useAuth0AccessToken();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!idToken || !accessToken) {
      return;
    }

    setLoading(true);
    const { sub: userId } = jwtDecode<JwtPayload>(idToken!);
    ProfileService.fetchUser({ userId: userId!, accessToken: accessToken! })
      .then(setUser)
      .catch(Sentry.captureException)
      .finally(() => setLoading(false));
  }, [idToken, accessToken]);

  return { user, cancelled, request, loading };
};
