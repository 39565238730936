/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import {
  DefaultTheme,
  NavigationContainer,
  useNavigationContainerRef,
} from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as Analytics from 'expo-firebase-analytics';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { CalendarNavButton } from '@/components';
import {
  MenuIcon,
  MenuIconFocused,
  ProfileIcon,
  ProfileIconFocused,
} from '@/components/icons';
import Colors from '@/constants/Colors';
import { useRootStore } from '@/data';
import {
  AddLocationScreen,
  DayMenuScreen,
  LandingScreen,
  LoginScreen,
  NutritionalInfoScreen,
  OnboardingScreen,
  ProfileScreen,
} from '@/screens';
import EmbedScreen from '@/screens/EmbedScreen';
import { PrivacyPolicyScreen } from '@/screens/PrivacyPolicyScreen';
import Sentry from '@/sentry';

import {
  EmbedStackParamList,
  MenuStackParamList,
  ProfileStackParamList,
  RootStackParamList,
  RootTabParamList,
} from '../../types';
import LinkingConfiguration from './LinkingConfiguration';

const safeAreaStyle = { flex: 1, backgroundColor: Colors.secondaryGreen };

const FRIENDLY_SCREEN_NAMES = {
  DayMenu: 'Menu',
  NutritionalInfo: 'Recipe Details',
  ProfileStack: 'Profile',
  AddLocation: 'Add Location',
  Root: 'Menu',
  Landing: 'Authenticator',
} as Record<string, string>;

export default function Navigation() {
  const navigationRef = useNavigationContainerRef();
  const routeNameRef = React.useRef<string>();

  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={DefaultTheme}
      documentTitle={{
        formatter: (_, screen) => {
          return `Meriwether Godsey - ${
            screen?.name
              ? FRIENDLY_SCREEN_NAMES[screen.name] || screen?.name
              : ''
          }`;
        },
      }}
      ref={navigationRef}
      onReady={() => {
        routeNameRef.current = navigationRef.getCurrentRoute()?.name;
      }}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.getCurrentRoute()?.name;

        if (previousRouteName !== currentRouteName) {
          if (Platform.OS !== 'web') {
            await Analytics.setCurrentScreen(currentRouteName).catch(
              Sentry.captureException,
            );
          }
        }

        // Save the current route name for later comparison
        routeNameRef.current = currentRouteName;
      }}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();
const EmbedStack = createNativeStackNavigator<EmbedStackParamList>();
const MenuStack = createNativeStackNavigator<MenuStackParamList>();
const ProfileStack = createNativeStackNavigator<ProfileStackParamList>();

const RootNavigator = observer(() => {
  const { userStore } = useRootStore();

  let navigatorScreens;
  if (!userStore.currentUser) {
    navigatorScreens = (
      <>
        <Stack.Screen
          name="Landing"
          component={LandingScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Login"
          component={LoginScreen}
          options={{
            headerShown: false,
            presentation: 'modal',
          }}
        />
      </>
    );
  } else if (userStore.acceptedTerms && userStore.onboardingComplete) {
    navigatorScreens = (
      <Stack.Screen
        name="Root"
        initialParams={{ screen: 'DayMenuStack' }}
        component={BottomTabNavigator}
        options={{ headerShown: false }}
      />
    );
  } else {
    navigatorScreens = (
      <Stack.Screen
        name="Onboarding"
        component={OnboardingScreen}
        options={{ headerShown: false }}
      />
    );
  }

  return (
    <Stack.Navigator>
      {navigatorScreens}
      <Stack.Screen
        name="Privacy"
        component={PrivacyPolicyScreen}
        options={{ title: 'Privacy Policy' }}
      />
      <Stack.Screen
        name="EmbedStack"
        component={EmbedStackScreen}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
});

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

const sharedTabOptions = {
  tabBarActiveBackgroundColor: Colors.secondaryGreen,
  tabBarInactiveBackgroundColor: Colors.secondaryGreen,
  headerShown: false,
  tabBarShowLabel: false,
};

function MenuStackScreen() {
  return (
    <MenuStack.Navigator initialRouteName="DayMenu">
      <MenuStack.Screen
        name="DayMenu"
        component={DayMenuScreen}
        options={sharedTabOptions}
      />
      <MenuStack.Screen
        name="NutritionalInfo"
        component={NutritionalInfoScreen}
        options={{
          title: 'Nutritional Information',
          presentation: 'modal',
        }}
      />
    </MenuStack.Navigator>
  );
}

function EmbedStackScreen() {
  return (
    <EmbedStack.Navigator initialRouteName="Embed">
      <EmbedStack.Screen
        name="Embed"
        component={EmbedScreen}
        options={sharedTabOptions}
      />
      <EmbedStack.Screen
        name="NutritionalInfo"
        component={NutritionalInfoScreen}
        options={{
          title: 'Nutritional Information',
          presentation: 'modal',
        }}
        initialParams={{
          nativeGoBack: true,
        }}
      />
    </EmbedStack.Navigator>
  );
}

function ProfileStackScreen() {
  return (
    <ProfileStack.Navigator>
      <ProfileStack.Screen
        name="Profile"
        component={ProfileScreen}
        options={sharedTabOptions}
      />
      <ProfileStack.Screen
        name="AddLocation"
        component={AddLocationScreen}
        options={{ ...sharedTabOptions, presentation: 'modal' }}
      />
    </ProfileStack.Navigator>
  );
}

// try using https://reactnavigation.org/docs/material-bottom-tab-navigator
// swiper: https://www.youtube.com/watch?v=cGTD4yYgEHc
const BottomTabNavigator = observer(() => {
  const { uiStore, userStore } = useRootStore();

  const initialRouteName =
    uiStore.firstScreen ||
    (userStore.currentLocation ? 'DayMenuStack' : 'ProfileStack');

  return (
    <>
      <SafeAreaView style={safeAreaStyle} edges={['bottom']}>
        <BottomTab.Navigator
          initialRouteName={initialRouteName}
          safeAreaInsets={{ bottom: 0, top: 0 }}
          screenOptions={{
            tabBarItemStyle: {
              paddingVertical: 2,
            },
            tabBarStyle: {
              backgroundColor: Colors.secondaryGreen,
              display: 'flex',
              justifyContent: 'space-around',
              paddingHorizontal: 64,
            },
          }}
        >
          {userStore.currentLocation && (
            <BottomTab.Screen
              name="DayMenuStack"
              component={MenuStackScreen}
              options={{
                ...sharedTabOptions,
                unmountOnBlur: Platform.OS === 'web',
                tabBarIcon: ({ focused, size }) =>
                  focused ? (
                    <MenuIconFocused size={size * 1.3} />
                  ) : (
                    <MenuIcon size={size} />
                  ),
              }}
            />
          )}
          <BottomTab.Screen
            name="ProfileStack"
            component={ProfileStackScreen}
            options={{
              ...sharedTabOptions,
              tabBarIcon: ({ focused, size }) =>
                focused ? (
                  <ProfileIconFocused size={size * 1.3} />
                ) : (
                  <ProfileIcon size={size} />
                ),
            }}
          />
        </BottomTab.Navigator>
      </SafeAreaView>
      <CalendarNavButton />
    </>
  );
});
