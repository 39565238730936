import { Center, Spinner, Text } from 'native-base';
import React from 'react';

export const MenuLoading = () => {
  return (
    <Center flex="1" bg="gray.100" testID="menuLoading">
      <Spinner size="lg" color="mg.green.200" />
      <Text mt="2">Gathering Ingredients, Preparing Meals.</Text>
    </Center>
  );
};
