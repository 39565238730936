import { useNavigation } from '@react-navigation/native';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Platform } from 'react-native';

import { AppLoadingMessage } from '@/components/AppLoadingMessage';
import { useRootStore } from '@/data/StoreProvider';
import Sentry from '@/sentry';

import { useAuth0User } from './useAuth0User';

export const Authenticator = observer(() => {
  const { user, cancelled, request } = useAuth0User();
  const { userStore, menuStore } = useRootStore();
  const navigation = useNavigation();

  useEffect(() => {
    request().catch(error => {
      Sentry.captureException(error);
    });
  }, [request]);

  useEffect(() => {
    if (cancelled) {
      navigation.navigate('Landing');
      return;
    }

    if (user) {
      // @ts-ignore
      if (user.statusCode === 401) {
        userStore.logout().then(() => {
          navigation.navigate('Landing');
        });
        return;
      }

      setTimeout(() => {
        userStore.authenticated(user);
        menuStore.fetchMenu();
        userStore.syncCurrentLocation();
      }, 500);
    }
  }, [cancelled, user, userStore, menuStore, navigation]);

  return (
    <AppLoadingMessage
      message="Authenticating..."
      showBg={Platform.OS !== 'ios'}
    />
  );
});
