import { Entypo } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { observer } from 'mobx-react';
import {
  Center,
  Heading,
  HStack,
  Icon,
  IconButton,
  Select,
  VStack,
} from 'native-base';
import React from 'react';

import { useRootStore } from '@/data';

export const LocationChanger = observer(() => {
  const { userStore } = useRootStore();
  const navigation = useNavigation();

  const handleChange = (value: any) => {
    userStore.changeCurrentLocation(value);
  };

  return (
    <Center bg="mg.bg">
      <VStack px="4" py="12" maxW="2xl" w="full">
        <Heading
          size={{ base: 'lg', lg: 'xl' }}
          textAlign="center"
          mb="3"
          fontWeight="600"
        >
          Dining Locations
        </Heading>
        <HStack space="3">
          <Select
            flex="1"
            isDisabled={userStore.locations?.length === 0}
            selectedValue={userStore.currentLocation?.slug}
            onValueChange={handleChange}
            placeholder="No locations added yet"
            _selectedItem={{
              bg: '#f8e7ea',
            }}
            fontSize={{ base: 'md', lg: '2xl' }}
          >
            {userStore.locations?.map(location => (
              <Select.Item
                key={location.token}
                label={location.name}
                value={location.slug}
              />
            ))}
          </Select>
          <IconButton
            bg="mg.green.300"
            colorScheme="dark"
            rounded="full"
            size="sm"
            m="auto"
            icon={<Icon as={Entypo} name="plus" color="white" />}
            // @ts-ignore
            onPress={() => navigation.navigate('AddLocation')}
          />
        </HStack>
      </VStack>
    </Center>
  );
});
