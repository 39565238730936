import { Center, Spinner } from 'native-base';
import React from 'react';
import { WebView } from 'react-native-webview';

export const PrivacyPolicyScreen = () => {
  return (
    <WebView
      source={{ uri: 'https://merig.com/menu-app-privacy-policy' }}
      startInLoadingState
      renderLoading={() => (
        <Center flex="1">
          <Spinner size="lg" color="mg.green.200" />
        </Center>
      )}
    />
  );
};
