import { Entypo } from '@expo/vector-icons';
import { observer } from 'mobx-react';
import { Badge, Icon, Text } from 'native-base';
import React from 'react';

import { useDietaryFlagList, useRootStore } from '@/data';

export const DietaryFlagBadge = observer(
  ({ dietaryFlagId, name }: { dietaryFlagId: string; name?: string }) => {
    const { fetching, dietaryFlags } = useDietaryFlagList();
    const {
      userStore: { dietaryFlagIds },
    } = useRootStore();

    if (fetching) {
      return null;
    }

    const display =
      name || dietaryFlags?.find(x => x.id === dietaryFlagId)?.name;

    if (!display) {
      return null;
    }

    return (
      <Badge
        colorScheme="info"
        rounded="xl"
        my="0.5"
        bg={
          dietaryFlagIds.includes(dietaryFlagId) ? 'mg.red.800' : 'mg.gray.200'
        }
        py="0.5"
      >
        {display ? (
          <Text
            color="white"
            textTransform="uppercase"
            fontSize={{ base: '2xs', lg: 'sm' }}
            fontWeight="500"
          >
            {display}
          </Text>
        ) : (
          <Icon as={Entypo} name="warning" size="xs" color="white" />
        )}
      </Badge>
    );
  },
);
