import { Center, Image, Spinner, Text } from 'native-base';
import React from 'react';
import { Platform } from 'react-native';

export const AppLoadingMessage = ({
  message,
  showBg,
}: {
  message?: string;
  showBg?: boolean;
}) => {
  return (
    <Center flex="1" bg="gray.100">
      {showBg && (
        <Image
          mt="6"
          source={require('@/assets/images/nux-bg-complete.png')}
          resizeMode={Platform.OS === 'web' ? 'cover' : 'stretch'}
          alt="loading background"
          w="full"
          h="full"
          position="absolute"
          bottom={0}
        />
      )}
      <Spinner size="lg" color="mg.green.200" />
      <Text mt="2">{message}</Text>
    </Center>
  );
};

AppLoadingMessage.defaultProps = {
  message: '',
  showBg: true,
};
