import { useAuth0 } from '@auth0/auth0-react';
import { useRoute } from '@react-navigation/native';
import { observer } from 'mobx-react';
import { Center, Image, ZStack } from 'native-base';
import React, { useEffect, useState } from 'react';

import { MenuHubService, ProfileService } from '@/api';
import {
  AppLoadingMessage,
  DismissableMessage,
  LoginButton,
  MerigLogo,
} from '@/components';
import { useRootStore } from '@/data';
import Sentry from '@/sentry';
import { ILocation } from '@/types';

const LandingScreen = observer(() => {
  const { menuStore, userStore } = useRootStore();
  const { isLoading, isAuthenticated, user } = useAuth0();
  const route = useRoute();
  const [justLoggedOut, setJustLoggedOut] = useState(false);
  const [fetchingLocation, setFetchingLocation] = useState(false);

  const locationToken = Object.keys(route.params || {}).filter(
    x => x !== 'logout' && x !== 'code' && x !== 'state',
  )[0];

  useEffect(() => {
    if (user) {
      async function handleUser() {
        const accessToken = await ProfileService.fetchAccessToken();
        ProfileService.fetchUser({
          userId: user!.sub!,
          accessToken,
        }).then(fullUserPayload => {
          userStore.authenticated(fullUserPayload);
          menuStore.fetchMenu();
          userStore.syncCurrentLocation();

          if (locationToken) {
            setFetchingLocation(true);
            MenuHubService.fetchLocation(locationToken)
              .then(({ data }: { data: ILocation & { error: Error } }) => {
                if (!data.error) {
                  userStore.addLocation(data);
                  setTimeout(() => {
                    userStore.changeCurrentLocation(data.slug);
                    setFetchingLocation(false);
                  }, 500);
                }
              })
              .catch(Sentry.captureException);
          }
        });
      }
      handleUser();
    }
  }, [isAuthenticated, locationToken, user, menuStore, userStore]);

  useEffect(() => {
    // @ts-ignore
    if (route.params?.logout) {
      setJustLoggedOut(true);
    }
  }, [route]);

  if (isLoading || fetchingLocation) {
    return <AppLoadingMessage message="Loading..." showBg={false} />;
  }

  if (isAuthenticated) {
    return <AppLoadingMessage message="Authenticating..." showBg={false} />;
  }

  const redirectUri = `${window.location.origin}/?${locationToken || ''}`;

  return (
    <ZStack
      h="full"
      w="full"
      mx="auto"
      justifyContent="center"
      alignItems="center"
      bg="white"
    >
      <Image
        source={require('@/assets/images/Group.png')}
        resizeMode="contain"
        alt="login bg"
        w="full"
        h="full"
        position="absolute"
        bottom={0}
      />
      <MerigLogo mt="-32" />
      <Center w="full" p="8" maxW="80" mx="auto" mt="6">
        <LoginButton redirectUri={redirectUri} />
      </Center>
      {justLoggedOut && (
        <DismissableMessage
          message="Logged out successfully"
          variant="success"
          onClose={() => setJustLoggedOut(false)}
        />
      )}
    </ZStack>
  );
});

export default LandingScreen;
