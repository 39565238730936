import { useMemo } from 'react';

import { useRootStore } from './StoreProvider';

export function useDietaryFlags() {
  const { menuStore } = useRootStore();

  return useMemo(
    () => ({
      fetching: !menuStore.dietaryFlags,
      dietaryFlags: menuStore.dietaryFlags,
    }),
    [menuStore.dietaryFlags],
  );
}

export function useDietaryFlagList() {
  const { menuStore } = useRootStore();
  const { canonical, nonCanonical } = menuStore.dietaryFlags || {};

  return useMemo(
    () => ({
      fetching: !menuStore.dietaryFlags,
      dietaryFlags:
        canonical && nonCanonical ? [...canonical, ...nonCanonical] : [],
    }),
    [menuStore.dietaryFlags, canonical, nonCanonical],
  );
}
