import * as SecureStore from 'expo-secure-store';

const VALUE_LENGTH_LIMIT = 256;

export const deleteStoredValue = async (key: string) => {
  const available = await SecureStore.isAvailableAsync();
  if (!available) {
    return;
  }

  const rawFragmentCount = await SecureStore.getItemAsync(
    `${key}_fragmentCount`,
  );

  if (!rawFragmentCount) {
    return;
  }

  const numberOfFragments = parseInt(rawFragmentCount, 10);

  return Promise.all([
    ...Array.from(Array(numberOfFragments)).map((_, index) =>
      SecureStore.deleteItemAsync(`${key}_${index}`),
    ),
    SecureStore.deleteItemAsync(`${key}_fragmentCount`),
  ]);
};

export const storeFragmented = async (key: string, value: string) => {
  const available = await SecureStore.isAvailableAsync();
  if (!available) {
    return;
  }

  const numberOfFragments = Math.ceil(value.length / VALUE_LENGTH_LIMIT);
  const chunked = value.match(new RegExp(`.{1,${VALUE_LENGTH_LIMIT}}`, 'g'));

  return Promise.all([
    ...(chunked || []).map((fragment, index) =>
      SecureStore.setItemAsync(`${key}_${index}`, fragment),
    ),
    SecureStore.setItemAsync(`${key}_fragmentCount`, String(numberOfFragments)),
  ]);
};

export const retrieveFromSecureStore = async (key: string) => {
  const available = await SecureStore.isAvailableAsync();
  if (!available) {
    return;
  }

  const rawFragmentCount = await retrieveOrCleanup(`${key}_fragmentCount`);

  if (!rawFragmentCount) {
    return;
  }

  const numberOfFragments = parseInt(rawFragmentCount, 10);
  const chunks = await Promise.all(
    Array.from(Array(numberOfFragments)).map((_, index) =>
      retrieveOrCleanup(`${key}_${index}`),
    ),
  );
  return chunks.join('');
};

export const checkExistence = async (key: string) => {
  const available = await SecureStore.isAvailableAsync();
  if (!available) {
    return false;
  }

  const rawFragmentCount = await retrieveOrCleanup(`${key}_fragmentCount`);
  return !!rawFragmentCount;
};

// There is a limitation on android about accessing
// secured entries after a reinstall.
// unfortunately there is nothing we can do other than
// cleaning up the entry and ensuring the user path
// accounts for that.
// ref: https://github.com/expo/expo/issues/1459
const retrieveOrCleanup = async (key: string) => {
  try {
    return await SecureStore.getItemAsync(key);
  } catch (e) {
    await SecureStore.deleteItemAsync(key);
    return '';
  }
};
