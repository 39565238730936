import { Box, Button, HStack, ScrollView, Text } from 'native-base';
import React from 'react';

import { useRootStore } from '@/data';

import { PrivacyPolicyLink } from '../PrivacyPolicyLink';
import { Container } from './Container';

export const TermsAcceptance = () => {
  const { userStore } = useRootStore();

  return (
    <Container title="Terms and Privacy">
      <Text my="2" textAlign="center" fontSize="sm">
        By signing up, you agree to this application's terms and conditions and
        our <PrivacyPolicyLink label="privacy policy" />
      </Text>
      <Box borderWidth="1" h="50%" borderColor="mg.borderColor" mt="3">
        <ScrollView h="full" my="3" px="3">
          <Text
            textAlign="justify"
            fontSize="sm"
            fontWeight="300"
            lineHeight="sm"
          >
            All information contained in this application is presented as a
            guide only. Ingredients and nutritional content may vary as
            substitutions may occur and manufacturers may change ingredients
            without our knowledge. We make every effort to identify ingredients,
            but we cannot assure against these changes or variances. Therefore,
            it is ultimately the customer's responsibility to determine what if
            any foods to consume. If you have allergies, dietary restrictions,
            or a food-related medical condition or sensitivity, you should
            consult with your doctor and with the manager at your dining
            location(s) to address your specific needs before consuming any food
            referenced on this platform. They will help review the available
            options and work to accommodate you.
          </Text>
        </ScrollView>
      </Box>
      <HStack space="3" mt="auto" mb="4">
        <Button
          flex="1"
          variant="secondary"
          onPress={() => userStore.rejectTerms()}
        >
          Decline
        </Button>
        <Button
          flex="1"
          variant="primary"
          onPress={() => userStore.acceptTerms()}
        >
          Accept
        </Button>
      </HStack>
    </Container>
  );
};
