import { RouteProp, useRoute } from '@react-navigation/native';
import { addEventListener, parse } from 'expo-linking';
import { useCallback, useEffect } from 'react';

import { MenuHubService } from '@/api';
import { useRootStore } from '@/data';
import { deleteStoredValue, retrieveFromSecureStore } from '@/secure-store';

export function useDeepLinkAddLocation() {
  const { userStore } = useRootStore();
  const route = useRoute<RouteProp<{ params: { token: string } }>>();

  const addLocation = useCallback(
    token => {
      MenuHubService.fetchLocation(token)
        .then(({ data }) => {
          if (data && userStore.currentUser) {
            userStore.addLocation(data);
          }
        })
        .finally(() => {
          deleteStoredValue('mg.locationTokenPending');
        });
    },
    [userStore],
  );

  const registerListener = useCallback(() => {
    addEventListener('url', ({ url }) => {
      const { queryParams } = parse(url);
      if (queryParams.token) {
        addLocation(queryParams.token);
      }
    });
  }, [addLocation]);

  const maybeAddLocation = useCallback(async () => {
    const storedToken = await retrieveFromSecureStore(
      'mg.locationTokenPending',
    );
    if (!storedToken && !route.params?.token) {
      return;
    }
    const token = storedToken || route.params.token;

    addLocation(token);
  }, [route, addLocation]);

  useEffect(() => {
    maybeAddLocation();
    registerListener();
  }, [maybeAddLocation, registerListener]);
}
