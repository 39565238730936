import React from 'react';
import { Circle, Ellipse, G, Path, Rect, Svg } from 'react-native-svg';

export const MenuIcon = ({ size }: { size: number }) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 98 66">
      <G
        stroke="#3C3C3C"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <G transform="translate(17.697 1)">
          <Ellipse cx={31.886} cy={32} rx={31.886} ry={32} />
          <Ellipse cx={31.886} cy={32} rx={24.954} ry={25.043} />
        </G>
        <Path d="M91.404 2.855a3.575 3.575 0 0 1 4.852 3.34v56.321A2.484 2.484 0 0 1 93.772 65h-3.4a2.484 2.484 0 0 1-2.484-2.445c-.206-13.141.735-21.142 2.823-24.001-3.338-3.343-4.793-12.864-4.363-28.564a7.87 7.87 0 0 1 5.056-7.135ZM5.697 23.26h1.82c1.028 0 1.862.835 1.862 1.864v38.013A1.863 1.863 0 0 1 7.516 65h-1.82a1.863 1.863 0 0 1-1.862-1.863V25.124c0-1.03.834-1.863 1.863-1.863ZM1 2.2v17.008c.592 2.574 2.458 3.862 5.597 3.862 3.14 0 4.971-1.288 5.494-3.862V2.2M4.758 17.696V2.855M8.455 17.696V2.855" />
      </G>
    </Svg>
  );
};

export const MenuIconFocused = ({ size }: { size: number }) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 98 66">
      <G
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#3C3C3C"
        strokeWidth={2}
      >
        <G transform="translate(16.697)">
          <Ellipse cx={31.886} cy={32} rx={31.886} ry={32} fill="#3C3C3C" />
          <Ellipse cx={31.886} cy={32} rx={24.954} ry={25.043} fill="#BFDFA4" />
        </G>
        <Path
          fill="#3C3C3C"
          d="M90.404 1.855a3.575 3.575 0 0 1 4.852 3.34v56.321A2.484 2.484 0 0 1 92.772 64h-3.4a2.484 2.484 0 0 1-2.484-2.445c-.206-13.141.735-21.142 2.823-24.001-3.338-3.343-4.793-12.864-4.363-28.564a7.87 7.87 0 0 1 5.056-7.135ZM4.697 22.26h1.82c1.028 0 1.862.835 1.862 1.864v38.013A1.863 1.863 0 0 1 6.516 64h-1.82a1.863 1.863 0 0 1-1.862-1.863V24.124c0-1.03.834-1.863 1.863-1.863ZM0 1.2v17.008c.592 2.574 2.458 3.862 5.597 3.862 3.14 0 4.971-1.288 5.494-3.862V1.2M3.758 16.696V1.855M7.455 16.696V1.855"
        />
      </G>
    </Svg>
  );
};

export const ProfileIcon = ({ size }: { size: number }) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 66 66">
      <G
        transform="translate(1 1)"
        stroke="#3C3C3C"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
      >
        <Circle cx={32} cy={32} r={32} />
        <Circle cx={32} cy={20} r={12} />
        <Path
          d="M15 58v-9.83a13 13 0 0 1 11.933-12.956A70.248 70.248 0 0 1 32.664 35c1.926 0 3.696.06 5.31.182C44.755 35.69 50 41.343 50 48.145V58h0"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
    </Svg>
  );
};

export const ProfileIconFocused = ({ size }: { size: number }) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 66 66">
      <G
        id="icon-profile-drk"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <G
          id="Group"
          transform="translate(2.000000, 2.000000)"
          stroke="#3C3C3C"
          stroke-width="2"
        >
          <Circle id="Oval" cx="30" cy="30" r="30" />
          <Circle id="Oval" fill="#3C3C3C" cx="30.5" cy="18.5" r="11.5" />
          <Path
            d="M14,38 L14,46.8903004 C14.0000018,53.7367129 19.3099595,59.409749 26.1414242,59.8619156 C27.5322376,59.9539719 29.0367906,60 30.6550831,60 C32.1230558,60 33.4948849,59.9621256 34.7705704,59.8863769 C41.6388872,59.4785416 47,53.7896491 47,46.9092345 L47,38 L47,38 C41.4058571,34.6666667 35.8744761,33 30.4058571,33 C24.9372381,33 19.468619,34.6666667 14,38 Z"
            id="Path-3"
            fill="#3C3C3C"
            stroke-linecap="round"
            stroke-linejoin="round"
            transform="translate(30.500000, 46.500000) scale(1, -1) translate(-30.500000, -46.500000) "
          />
          <Path
            d="M14,55 C19.5,58.3333333 25,60 30.5,60 C36,60 41.5,58.3333333 47,55"
            id="Path"
          />
        </G>
      </G>
    </Svg>
  );
};

export const CalendarIcon = ({
  size,
  color,
}: {
  size: number;
  color?: string;
}) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 66 71" color={color}>
      <G
        transform="translate(1 1)"
        stroke={color}
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
      >
        <Rect y={5} width={64} height={64} rx={6} />
        <Path d="M6 5h52a6 6 0 0 1 6 6v8H0v-8a6 6 0 0 1 6-6ZM7 34.21h9.615V25H7zM20.462 34.21h9.615V25h-9.615zM33.923 34.21h9.615V25h-9.615zM47.385 34.21H57V25h-9.615zM7 47.105h9.615v-9.211H7zM20.462 47.105h9.615v-9.211h-9.615zM33.923 47.105h9.615v-9.211h-9.615zM47.385 47.105H57v-9.211h-9.615zM7 60h9.615v-9.21H7zM20.462 60h9.615v-9.21h-9.615zM33.923 60h9.615v-9.21h-9.615zM47.385 60H57v-9.21h-9.615z" />
        <Rect fill={color} x={15} width={6} height={11} rx={3} />
        <Rect fill={color} x={42} width={6} height={11} rx={3} />
      </G>
    </Svg>
  );
};

CalendarIcon.defaultProps = {
  color: '#3C3C3C',
};
