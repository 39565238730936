import React, { PropsWithChildren, useContext } from 'react';

import { RootStore } from './Store';

let store: RootStore;

const StoreContext = React.createContext<RootStore | undefined>(undefined);
export const RootStoreProvider = ({
  children,
  _store,
}: PropsWithChildren<{ _store?: RootStore }>) => {
  const root = _store || (store ?? new RootStore());
  return <StoreContext.Provider value={root}>{children}</StoreContext.Provider>;
};

RootStoreProvider.defaultProps = {
  _store: undefined,
};

export const useRootStore = () => {
  const rootStore = useContext(StoreContext);
  if (rootStore === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider');
  }

  return rootStore;
};
