import { useAuth0 } from '@auth0/auth0-react';
import { Entypo } from '@expo/vector-icons';
import { Button, Icon } from 'native-base';
import React from 'react';

const LoginButton = ({ redirectUri }: { redirectUri: string }) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      colorScheme="green"
      w="full"
      bg="mg.green.300"
      shadow="4"
      justifyContent="flex-start"
      endIcon={
        <Icon
          as={Entypo}
          name="chevron-right"
          size="sm"
          alignSelf="flex-end"
          ml="auto"
        />
      }
      mb="4"
      mx="auto"
      size="lg"
      _stack={{
        alignContent: 'space-between',
        w: 'full',
      }}
      onPress={() =>
        loginWithRedirect({
          redirectUri,
        })
      }
    >
      Go to Sign In
    </Button>
  );
};

export default LoginButton;
