import { Auth0Provider } from '@auth0/auth0-react';
import React, { PropsWithChildren } from 'react';

import { Config } from '@/constants';

const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Auth0Provider
      cacheLocation="localstorage"
      domain={Config.auth0.domain!}
      clientId={Config.auth0.clientId!}
      redirectUri={window.location.href}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
