import Constants from 'expo-constants';

export const Config = {
  isDevelopment: process.env.IS_DEVELOPMENT === 'true',
  auth0: {
    domain: process.env.AUTH0_DOMAIN,
    clientId: process.env.AUTH0_CLIENT_ID,
    apiId: process.env.AUTH0_API_ID,
    apiSecret: process.env.AUTH0_API_SECRET,
  },
  apiUrl: process.env.MENU_HUB_URL,
  appVersion: Constants.manifest?.version,
  appSha: process.env.EAS_BUILD_GIT_COMMIT_HASH || process.env.CIRCLE_SHA1,
  maxDayRange: 28,
  sentryDSN: process.env.SENTRY_DSN,
  sentryDebugOn: process.env.SENTRY_DEBUG_ON === 'true',
};
