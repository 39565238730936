import { IMenuItem } from '@/types';

import { formatNutritionalQuantity } from './formatNutritionalQuantity';
import { formatServingSize } from './formatServingSize';

export const formatMenuItem = (item: IMenuItem): Record<string, string> => {
  const nutritionalInfo = item.attributes.recipeNutrition;
  const isIncomplete = !nutritionalInfo?.allergensComplete;
  if (!nutritionalInfo) {
    return {};
  }

  const calories = nutritionalInfo?.calories;

  return {
    calories: isIncomplete
      ? ''
      : calories
      ? Math.floor(calories).toString()
      : '',
    carbs: isIncomplete
      ? ''
      : formatNutritionalQuantity(nutritionalInfo.carbsG, 'g'),
    cholesterol: isIncomplete
      ? ''
      : formatNutritionalQuantity(nutritionalInfo.cholesterolMg, 'mg'),
    fats: isIncomplete
      ? ''
      : formatNutritionalQuantity(nutritionalInfo.fatG, 'g'),
    fibers: isIncomplete
      ? ''
      : formatNutritionalQuantity(nutritionalInfo.fiberG, 'g'),
    protein: isIncomplete
      ? ''
      : formatNutritionalQuantity(nutritionalInfo.proteinG, 'g'),
    saturatedFats: isIncomplete
      ? ''
      : formatNutritionalQuantity(nutritionalInfo.fatSaturatedG, 'g'),
    serving: isIncomplete ? '' : formatServingSize(item),
    sodium: isIncomplete
      ? ''
      : formatNutritionalQuantity(nutritionalInfo.sodiumMg, 'mg'),
    sugar: isIncomplete
      ? ''
      : formatNutritionalQuantity(nutritionalInfo.sugarG, 'g'),
    transFats: isIncomplete
      ? ''
      : formatNutritionalQuantity(nutritionalInfo.fatTransG, 'g'),
  };
};
