import { Center, Heading, Text } from 'native-base';
import React from 'react';

export const NutritionalBlock = ({
  label,
  value,
  ...props
}: {
  label: string;
  value: string;
}) => {
  return (
    <Center {...props}>
      <Heading
        size={{ base: 'xxs', lg: 'xs' }}
        color="mg.gray.100"
        fontWeight="600"
        textTransform="uppercase"
        testID={`nutritional-info-${label}`}
      >
        {label}
      </Heading>
      <Text
        size="xxs"
        fontSize={{ base: '2xs', lg: 'sm' }}
        fontWeight="600"
        color="mg.dark.300"
      >
        {value || '--'}
      </Text>
    </Center>
  );
};
