/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Root: {
        screens: {
          DayMenuStack: {
            path: '/menu',
            screens: {
              DayMenu: 'index',
            },
          },
        },
      },
      Landing: {
        path: '',
      },
      Modal: 'modal',
      NotFound: '*',
      Privacy: {
        screens: {
          PrivacyPolicyScreen: 'Privacy',
        },
      },
      EmbedStack: {
        path: 'embed',
        screens: {
          Embed: '/:dayPart/:date/:slug',
        },
      },
    },
  },
};

export default linking;
