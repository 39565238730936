import { Entypo } from '@expo/vector-icons';
import { observer } from 'mobx-react';
import {
  Center,
  Heading,
  HStack,
  Icon,
  Spinner,
  Text,
  VStack,
} from 'native-base';
import React from 'react';
import { TouchableOpacity } from 'react-native';

import { useDietaryFlags, useRootStore } from '@/data';

import { DietaryFlagBadge } from './Cards/DietaryFlagBadge';

const DietaryFlagToggle = observer(
  ({ dietaryFlag }: { dietaryFlag: { id: string; name: string } }) => {
    const { userStore } = useRootStore();

    return (
      <TouchableOpacity
        onPress={() => userStore.toggleDietaryFlagTracking(dietaryFlag.id)}
      >
        <DietaryFlagBadge dietaryFlagId={dietaryFlag.id} />
      </TouchableOpacity>
    );
  },
);

export const DietaryFlagSelection = observer(() => {
  const { fetching, dietaryFlags } = useDietaryFlags();

  if (fetching) {
    return <Spinner color="mg.green.200" />;
  }

  const totalFlags =
    dietaryFlags!.canonical.length + dietaryFlags!.nonCanonical.length;

  return (
    <Center alignItems="center" px="4" py="6" bg="white" maxW="2xl" mx="auto">
      <Heading
        size={{ base: 'lg', lg: 'xl' }}
        textAlign="center"
        mb="3"
        fontWeight="600"
      >
        Dietary Flags
      </Heading>
      <Text fontSize={{ base: 'sm', lg: 'md' }} fontWeight="300">
        Would you like to keep track of food allergens or ingredients?
      </Text>
      <Text fontSize={{ base: 'sm', lg: 'md' }} fontWeight="300">
        Select the flags below to see them highlighted on recipes.
      </Text>
      {totalFlags === 0 && (
        <VStack
          alignContent="center"
          alignItems="center"
          mt="4"
          bg="red.100"
          p="4"
          rounded="xl"
        >
          <Icon as={Entypo} name="traffic-cone" size="sm" color="mg.red.800" />
          <Text
            fontSize="sm"
            fontWeight="400"
            color="mg.red.800"
            flexWrap="wrap"
          >
            Could not retrieve available dietary flags.
          </Text>
          <Text
            fontSize="sm"
            fontWeight="400"
            color="mg.red.800"
            flexWrap="wrap"
          >
            Our engineers have been notified.
          </Text>
        </VStack>
      )}
      <HStack
        alignContent="center"
        alignItems="flex-start"
        flexWrap="wrap"
        marginTop="4"
        space={[1, 2]}
        w="full"
      >
        {dietaryFlags!.canonical.map(dietaryFlag => (
          <DietaryFlagToggle key={dietaryFlag.id} dietaryFlag={dietaryFlag} />
        ))}
      </HStack>
      <HStack
        alignContent="center"
        alignItems="flex-start"
        flexWrap="wrap"
        marginTop="4"
        space="1"
        w="full"
      >
        {dietaryFlags!.nonCanonical.map(dietaryFlag => (
          <DietaryFlagToggle key={dietaryFlag.id} dietaryFlag={dietaryFlag} />
        ))}
      </HStack>
    </Center>
  );
});
