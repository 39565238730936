import { FontAwesome } from '@expo/vector-icons';
import { RouteProp, useNavigation } from '@react-navigation/native';
import { observer } from 'mobx-react';
import { IconButton, ScrollView, Stack } from 'native-base';
import React, { useEffect, useLayoutEffect } from 'react';
import { Platform } from 'react-native';

import { NutritionalLabel } from '@/components';
import { FoodCard } from '@/components/Cards';
import { useRootStore } from '@/data';
import Sentry from '@/sentry';
import { logEvent } from '@/utils';

const NutritionalInfoScreen = observer(
  ({
    route,
  }: {
    route: RouteProp<{
      params: { itemId: string; dayPart: string; nativeGoBack: false };
    }>;
  }) => {
    const navigation = useNavigation();
    console.log('....', route.params);

    const {
      menuStore: { calorieDisplay, menuItems },
    } = useRootStore();
    if (!route.params.itemId) {
      Sentry.captureException(
        new Error('NutritionalInfoScreen did not receive an itemId'),
      );
    }
    const item = menuItems?.find(x => x.id === route.params.itemId);

    useEffect(() => {
      if (route.params.itemId) {
        logEvent('view_item');
      }
    }, [route.params.itemId]);

    useLayoutEffect(() => {
      if (Platform.OS !== 'web' || route.params.nativeGoBack) {
        return;
      }

      const goBack = () => {
        //@ts-ignore
        navigation.navigate('DayMenu', {
          dayPart: route.params.dayPart,
        });
      };

      navigation.setOptions({
        headerLeft: () => (
          <IconButton
            mx="3"
            px="3"
            _hover={{
              background: 'mg.green.100',
            }}
            icon={<FontAwesome name="arrow-left" size={20} />}
            onPress={goBack}
          />
        ),
      });
    }, [navigation, route.params]);

    if (!item) {
      Sentry.captureException(
        new Error(
          `NutritionalInfoScreen - could not find item for itemId ${route.params.itemId}`,
        ),
      );
      return null;
    }

    return (
      <ScrollView>
        <Stack margin="6" space="4" w="full" maxW="2xl" mx="auto" px="4">
          <FoodCard item={item} />
          {calorieDisplay !== 'hide_nutrition_block' && (
            <NutritionalLabel item={item} />
          )}
        </Stack>
      </ScrollView>
    );
  },
);

export default NutritionalInfoScreen;
